import React from "react"

import ContentPage from "../../components/contentPage"
import { Link } from "gatsby";
import styles from "./index.module.css";

const TermsOfUse = () => (
  <>
    <ContentPage title={'Terms of Use'} isSmallFonts={true}>
        
	<h4>1. Introduction</h4>

	<p>www.mprofit.in, cloud.mprofit.in and app.mprofit.in (collectively referred to as “Website”) and its related mobile, cloud and desktop software applications (collectively referred to as “MProfit Software” as set out in Schedule I) (Website and MProfit Software are collectively referred to as “Platform”) are owned by MProfit Software Private Limited (referred to as “MProfit”), a company incorporated under the provisions of the Companies Act, 2013, having its registered office at 108-B Mittal Tower, Nariman Point, Mumbai-400021, India. For the purposes of this Terms of Use, accessing, installing or using the Platform or MProfit Services, including such services as set out in Schedule I, shall hereinafter be collectively referred to as the "Services".</p>
	<p>This Terms of Use is a legal, binding contract between every user (whether an individual, firm, association of persons, company or legal entity) (“You”, “Your”, or “User”) and MProfit Software Private Limited (“We”, “Us”, “Our”, or “MProfit”). Please read these terms and conditions carefully. The following Terms of Use govern Your use and access of the Platform and/or Services. By using, installing or accessing the Platform in any way, including registering on the Platform, using, sharing, browsing and/or downloading any Services, You agree to be bound by the Terms of Use set forth herein. If You do not agree to these Terms of Use, You are not authorized to access, install and/or use this Platform or the Services.</p>
	<p>Access to and use of password protected and/or secure areas of the Platform and/or use of the Services is restricted to User(s) with accounts only. You shall not obtain or attempt to obtain unauthorized access to such parts of this Platform and/or Services, or to any other protected information, through any means not intentionally made available by Us for Your specific use. A breach of this provision may be an offence under the applicable laws including but not limited to the Information Technology Act, 2000.</p>
	<p>We reserve the right to update this Terms of Use from time to time without any prior notice to the User. Please read this entire Terms of Use carefully before using Our Platform or Our Services, as using them (with or without subscription fees/payment) will indicate Your acceptance to Our Terms of Use.</p>

	<h4>2. Definitions & Interpretations</h4>

	<p>Unless otherwise defined, the definitions and provisions in respect of interpretation set out in Schedule I will apply to these Terms of Use.</p>

	<h4>3. Eligibility</h4>

	<p>In order to avail the Services provided on the Platform, You must be legally competent, within the meaning of the Indian Contract Act, 1872, to enter into binding, legal contracts such as this Terms of Use.</p>
	<p>If You are a purchaser of these Services on behalf of an entity, You agree that the entity has authorized You on its behalf, and the entity and its respective users shall each be bound by this Terms of Use.</p>
	<p>As a minor if You wish to use Our Services, such use shall be made available to You by Your legal guardian or parent, who has agreed to these Terms of Use. In the event a minor utilizes the Platform/Services, it is assumed that he/she has obtained the consent of the legal guardian or parent(s) and such use is made available by the legal guardian or parent(s). MProfit will not be responsible for any consequence that arises as a result of usage of Our Platform or any of Our Services by a minor.</p>
	<p>By using the Services, You warrant that all the data provided by You is accurate and complete and that, in case of a minor, You have obtained the consent of Your parent(s)/ legal guardian.</p>
	<p>You agree to use the Platform only in compliance with these Terms of Use and applicable law, and in a manner that does not violate legal rights of MProfit or any third party(ies).</p>
	<p>You acknowledge that MProfit does not have the responsibility to ensure that You conform to the aforesaid eligibility criteria. It shall be Your sole responsibility to ensure that You meet the required qualification. All Users shall seek the consent of their relatives or affiliates before providing any Personal Data about them on the Platform.</p>

	<h4>4. General Use of Services; User Restrictions</h4>
	<p>You agree to comply with any and all the guidelines, notices, operating rules, policies and instructions pertaining to the use of the Services and/or access to the Platform, as well as any amendments to the aforementioned, issued by Us, from time to time. We reserve the right to revise these guidelines, notices, operating rules and policies and instructions at any time and You are deemed to be aware of and bound by any changes to the foregoing upon their publication on the Platform.</p>

	<p>Further, You agree and undertake NOT to:</p>

	<ol type="a">
	<li>Impersonate any person or entity or to falsely state or otherwise misrepresent Your affiliation with any person or entity;</li>
	<li>Use the Platform or Services for illegal or immoral purposes;</li>
	<li>Attempt to gain unauthorized access to or otherwise interfere or disrupt other computer systems or networks connected to the Platform or Services;</li>
	<li>Post, promote or transmit through the Platform or Services any Prohibited Materials;</li>
	<li>Interfere with another User’s utilization and enjoyment of the Platform or Services;</li>
	<li>Use or upload, in any way, any software or material that contains, or which You have reason to suspect that contains, viruses, damaging components, malicious code or harmful components which may impair or corrupt the Platform’s data or damage or interfere with the operation of another User’s Platform or Services; and</li>
	<li>Use the Platform or Services other than in conformance with the acceptable use policies of any connected computer networks, any applicable Internet standards and any other applicable laws.</li>
	</ol>

	<p>We may, from time to time and without giving any reason or prior notice, upgrade, modify, suspend or discontinue the provision of or remove, whether in whole or in part, the Platform or any Services and shall not be liable if any such upgrade, modification, suspension or removal prevents You from accessing the Platform or any part of the Services. We reserve the right, but shall not be obliged to:</p>

	<ol type="a">
	<li>Investigate any violation of the terms and conditions contained herein and may take any action We deem appropriate;</li>
	<li>Prevent or restrict access of any User to the Platform and/or the Services if they are in violation of the Terms of Use;</li>
	<li>Report any activity We suspect to be in violation of any applicable law, statute or regulation to the appropriate authorities and to co-operate with such authorities; and/or</li>
	<li>Request any additional information or data from You in connection with Your use of the Services and/or access of the Platform at any time, if We have reasonable grounds to suspect that You have provided inaccurate, misleading or fraudulent information and/or data.</li>
	</ol>

	<p>Purchases of any Services would be subject to these Terms of Use. In addition to these Terms of Use, the use of specific aspects of the Services, or updated versions of the Services offered by Us or Our designated sub-contractors, resellers, affiliates or referral partners may be subject to additional terms and conditions, which shall be notified to You from time to time, and such additional terms and conditions will apply in full force and effect. You hereby acknowledge and agree to be bound to the additional terms and conditions as notified by Us from time to time. </p>
	<p>The use of the Platform and Services, after the expiry of the trial period, will only be made available to You/User after the payment of the required subscription charges as detailed on the Website.</p>
	<p>All payments due to MProfit under this Agreement shall be made in full, without deduction for any reason, including but not limited to set-off, counterclaim or other equitable or lawful claim.</p>
	<p>If You participate in the MProfit Referral Program to refer new customers to MProfit, You accept all the Terms and Conditions of the MProfit Referral Program available on the MProfit Website (<ReferralTermsLink text={'www.mprofit.in/referral-terms'}/>).</p>

	<h4>5. Data Onboarding Services</h4>
	<p>In addition to the Services provided herein, MProfit may assist You with onboarding Your data onto the Platform. In order to avail such onboarding services, You may follow the directions available on the Website or reach out to us directly at support@mprofit.in. The fees/ charges for such onboarding services shall be in addition to the fees/charges payable by You for access and usage of the Services. Such fees shall be communicated to You in advance and You will be required to make payment of the same to MProfit prior to commencement of the onboarding services. </p>
	<h4>6. Change in Scope or Fees</h4>
	<p>We hereby reserve the right to change the scope of Services offered, or the fees. You may be required to pay for use or access to such Services at any time, at Our sole discretion. By continuing to use, access or install the Services/Platform/MProfit Software, You agree to the revised scope of such Services and/or the revised fee payable for usage of the same. MProfit shall not be liable for any reason whatsoever for any loss or damages that You or any of Your affiliates may suffer as a result of such change to the Services or fees payable. All such changes to the Services, fees or Platform shall be displayed on the Platform for Your perusal. </p>

	<h4>7. Limited License</h4>
	<p>Subject to the terms and conditions of this Terms of Use, We grant the User a personal, limited, non-commercial, non-exclusive, non-transferable, non-sub-licensable, and revocable license to avail the Services on the Platform. The Services may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of MProfit. Any unauthorized commercial use of Our Services or Platform shall attract immediate termination of Your relationship with MProfit and subsequent monetary penalization as We may deem fit. Termination and penalisation for the breach will not alter or affect Our right to exercise any other legal remedies for such breach.</p>
	<h4>8. Third Party Content</h4>
	<p>The Services may include links to websites and services provided by third party(ies) (“Third-Party Links”). Your interaction with these Third-Party Links will be subject to the terms (including privacy policies) established by such Third-Party Links. MProfit is not responsible for any materials, information, goods or services available through or advertised on such Third-Party Links, or the privacy or other practices of the Third-Party Links. The inclusion of any Third-Party Links does not imply endorsement by MProfit of those sites or any association with its operators.</p>
	<p>MProfit disclaims any and all responsibility for any disclosure of information or any other practices of any third-party. MProfit expressly disclaims any warranty with respect to Your personal or other information that may be collected, processed, shared or retained by any third-party.</p>

	<h4>9. Privacy Policy</h4>
	<p>You hereby acknowledge that when You use the Platform, We may collect certain personal information from You. Such collection of information shall be in accordance with Our Privacy Policy (<PrivacyLink text={'www.mprofit.in/privacy'}/>) which is incorporated into this Terms of Use by reference. You acknowledge and agree that Your submission of such information is voluntary on Your part.</p>

	<h4>10. Disclaimer of Warranties and Liabilities</h4>
	<p>You expressly understand and agree that, to the maximum extent permitted by applicable law, the Platform and Services provided by Us, are on an “as is” basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, satisfactory quality or fitness for a particular purpose. We shall not be liable for any third-party product or services. You also acknowledge that Our Platform and its related functions may contain bugs, errors and/or other defects which We do not claim any liability of. Your access to Our Platform and other related links is at Your sole risk.</p>
	<p>Without limiting the foregoing, MProfit makes no warranty that the Services provided:</p>
	<ol type="a">
	<li>will be timely, reliable and error/bug free;</li>
	<li>will meet the User’s requirements;</li>
	<li>will ensure that all errors are corrected; and</li>
	<li>will be compatible with all operating systems, smart phones, tablets, laptops, desktops, networks, including all versions of them.</li>
	</ol>

	<p>You assume full responsibility for implementing sufficient procedures and checks to satisfy Your requirements for the installation, accuracy and suitability of MProfit Software and MProfit Services, and for maintaining any means that You may require for the reconstruction of lost data or subsequent manipulations or analyses of the information under this Terms of Use, or for safeguard of Your Personal Data.</p>
	<p>MProfit Software may be unable to process data received from certain types of documents or transaction statements or files/ reports and You will be required to modify the formats of such documents to be able to avail the Services. You shall be responsible for ensuring that all such documents, statements, files and reports shall be in a format that MProfit deems compatible in order to ensure that You shall be able to utilize the Services. MProfit shall not be liable for the inability of the Website or Platform to process Your files, transaction statements, reports or documents.</p>
	<p>You shall be responsible for keeping Your username and password confidential and MProfit shall not be liable in the event that any information transmitted or received through the Services or the Platform is accessed by unauthorized third parties and/or disclosed by Us or Our officers, employees or agents to third parties purporting to be You or purporting to act under Your authority. Transmissions over the Internet and electronic mail may be subject to interruption, transmission blackout, and delayed transmission due to Internet traffic or incorrect data transmission due to the public nature of the Internet.</p>
	<p>You also acknowledge that You shall be solely responsible for any and all damage to the computer system or any data as a result of download of any materials provided in the Services. </p>
	<p>MProfit makes every effort to avoid errors, omissions, mistakes, discrepancies, bugs and inaccuracies in any data processed by MProfit, including but not limited to reports and corporate action details that it provides to You. However, please note that the accuracy of any data processed by MProfit including but not limited to reports and corporate actions is subject to the accuracy of data either imported or manually entered in MProfit, as well as the accuracy of current/historical pricing & corporate action data provided by third-party data providers.</p>
	<p>MProfit makes every effort to ensure that the computation of capital gains processed by MProfit is as per the Income Tax rules prevalent in India as amended from time to time. However, You are advised to verify the accuracy of their capital gain reports generated in MProfit before filing Income Tax Returns. The Turnover Report (Section 44AB) under the MProfit F&O Module in the MProfit Desktop application is provided for informational purposes only. It has certain limitations in terms of computing turnover for options.</p>
	<p>If any mistake, error, omission, discrepancy, bug or inaccuracy in MProfit reports, current prices, historical prices, FMV prices, corporate action details, computed capital gains or any other data processed by MProfit is noticed anywhere in the MProfit Platform or Services, it may be brought to our notice for immediate correction. MProfit will make its best efforts to try and resolve the inaccuracy, subject to getting accurate data from third-party vendors or subject to technical limitations faced in the resolution of such inaccuracy. However, MProfit will not be responsible for any loss, damage or inconvenience to anyone, of any kind, in any manner, resulting due to mistakes, errors, discrepancies, bugs or inaccuracies in relation to any data processed or reported by MProfit including but not limited to reports, computed capital gains, corporate action details, computations & import processes. Accordingly, all Users are advised to verify the accuracy of their data entered in or reported by MProfit.</p>
	<h4>11. Limitation of Liability</h4>
	<p>Use of the Platform and Services is at Your own risk. While we make reasonable efforts to ensure the safety and functionality of Our Website, these efforts may fail and errors may occur.</p>
	<p>MProfit, in no event, shall be liable for any special, incidental, indirect, or consequential loss related to the Services and direct/indirect loss of income, profit, data, opportunity, as well as any liability related to recovery or damage. MProfit advises its User(s) to verify every information available on the Platform before taking it into consideration. We shall not be liable for any misinformation, inaccurate data, data theft, system damage, or any other loss occurring as a result of usage of the Platform, Services or Third-Party Links.</p>
	<p>NOTWITHSTANDING ANYTHING TO THE CONTRARY, THE LIABILITY OF MPROFIT, IF ANY, ARISING OUT OF THIS TERMS OF USE, THE TERMINATION THEREOF, USE, INSTALLATION OR ACCESS TO THE SERVICES/ PLATFORM AND/OR DAMAGES/CLAIMS/LOSSES RELATING TO SAME SHALL BE LIMITED TO THE ACTUAL AMOUNTS PAID BY YOU TO MPROFIT FOR THE SERVICES IN THAT CALENDAR YEAR. </p>

	<p>The limitations and exclusions in this Clause 11 apply to the maximum extent permitted by applicable law.</p>

	<h4>12. User Acknowledgement</h4>
	<p>You agree that You are solely responsible for the content that You or Your authorized users create, transmit, display, use, store and/or redistribute while using Our Services. You and/or any of Your authorized users are solely responsible and liable for any data created, uploaded or imported by You to the Platform. MProfit shall not be responsible or liable for the same in any manner whatsoever, including but not limited to any deletion, correction, damage or loss of the data. You also agree that We have no obligation with respect to any User content. If We receive notice that any content created by a User while using the Services violates any law or infringes any third-party rights, We shall have the right to immediately suspend the User’s access to the Services without any prior notice.</p>

	<p>You acknowledge that You are responsible for the accuracy, legality, quality, integrity, and intellectual property ownership rights of Your content. You must ensure that Your content complies with these Terms of Use, along with MProfit’s Privacy Policy and other applicable policies, laws and regulations. You acknowledge that We may access, use, preserve, or disclose Your account information and user content pursuant to a court order or directions from law enforcement agencies. You agree that disclosure is reasonably necessary to (i) comply with legal process or request; (ii) enforce these Terms of Use; (iii) security or technical issues; (iv) detect or prevent fraud; and (v) protect the rights, property or safety of MProfit, its Users, or the public as permitted by law. </p>
	<h4>13. Registration and Payment</h4>

	<p>User shall create an account with MProfit in order to avail the Services provided on the Platform, as well as to make purchases of the Services (“User Account”). You must keep Your account and registration details current and correct for communications related to Your purchases from the Platform. </p>

	<p>You are to notify Us immediately if You have knowledge that or have reason for suspecting that the confidentiality of the username and/or password has been compromised or if there has been any unauthorized use of the username and/or password or if Your personal data requires updating.</p>
	<p>By creating the User Account, You agree to the terms required to protect the confidentiality of Your username and password for the User Account and shall solely be liable for any activity conducted through the User Account. You agree to accept all risk and responsibilities for any activity done under the User Account. You also agree to receive messages/ emails from MProfit (“Promotional Communications”) regarding the Platform, Your Services and preferences. </p>
	<p>By sharing Your number, You consent to MProfit calling You to provide information or support related to the Platform or Your Services.</p>
	<p>MProfit reserves the right and sole discretion to refuse access to the Platform, terminate any User Account, remove/restrict content at any time, with or without notice to You, in case any illegal or immoral activity being conducted from Your User Account is brought to Our notice, or We have reason to believe that any information provided by You is incomplete, inaccurate or outdated. </p>
	<p>We shall determine the fixed fees payable by You whereby You shall be entitled to utilize the Services for a fixed period upon payment of the same (“Subscription Fees”). These subscription plans may be available on the Platform from time to time and We may at our sole discretion modify the pricing, features, functionalities, tenure and/or reports included in the plans. You are responsible for any taxes, including goods and services tax, resulting from Your use of the Platform. All Subscription Fees are non-refundable, in line with MProfit’s Refund Policy (<RefundPolicyLink text={'www.mprofit.in/refund-policy'}/>). </p>
	<p>Failure to pay: If the User fails to pay the Subscription Fees pursuant to the purchase of the Services, or payment is cancelled for any reason whatsoever, then without prejudice to any other right or remedy available to Us, We shall be entitled to cancel the purchase or suspend delivery of the Services until payment is made in full.</p>

	<h4>14. Intellectual Property Rights</h4>

	<p>All information including but not limited to MProfit’s content, trade names, logo, images and Services are proprietary information (“Intellectual Property”) of MProfit and its affiliates, agents, directors and employees, and no such Intellectual Property may be reproduced, copied, re-published, posted, distributed or transmitted in any way without the prior consent of MProfit.</p>
	<p>We respect the intellectual property rights of others, and You agree to protect Our Intellectual Property rights during and after the term of this Terms of Use. No part or parts of the Platform, or any Services may be reproduced, reverse engineered, decompiled, disassembled, separated, altered, distributed, republished, displayed, broadcast, hyperlinked, mirrored, framed, transferred or transmitted in any manner or by any means or stored in an information retrieval system or installed on any servers, system or equipment without Our prior written permission. Permission will only be granted to You to use the Services for personal and non-commercial uses, provided that You do not modify the content of the Services and that We retain all proprietary rights contained in the Services.</p>
	<p>Trademarks: The Trademarks are registered and unregistered trademarks of MProfit. Nothing on the Platform and in these Terms of Use shall be construed as granting, by implication, estoppel, or otherwise, any license or right to use (including as a meta tag or as a “hot” link to any other website) any Trademarks displayed on the Services, without Our written permission.</p>

	<p>You hereby agree and declare that You shall not at any time:</p>

	<ol type="a">
	<li>Take any action which shall or may impair Our or Our service provider’s right, title or interest to the said marks or create any right, title or interest therein or thereto which may be adverse to Us or the service providers respectively;</li>
	<li>Use the said marks upon or in relation to any goods other than those displayed on the Platform or for any other purpose;</li>
	<li>Register deceptively similar marks in any additional class or country.</li>
	</ol>

	<p>In the event that We receive notification of alleged intellectual property rights complaints that do not comply with the applicable laws, We shall endeavor to promptly remove or disable access to the illegal infringing material. We may also terminate the User Accounts of the infringers.</p>

	<h4>15. Third Party and/or Open Source Software</h4>
	<p>MProfit acknowledges that it may use Third Party &/or Open Source Software for the purpose of this Terms of Use. MProfit makes no claims on the Intellectual Property of the Third Party &/or Open Source Software. The list of some of the Third Party &/or Open Source Software used by MPROFIT can be viewed <ThirdPartyLicensesLink text={'here'}/> and will be updated from time to time.</p>
	<p>For installation and use of MProfit Software, You may require Third Party &/or Open Source Software, which are redistributed/bundled along with MProfit Software. You cannot modify, translate, reverse engineer, decompile, disassemble or create derivative works based on MProfit Software and associated MProfit Services. You cannot rent, lease, or otherwise transfer rights to any third party or remove any proprietary notices or labels on or in MProfit Software, associated MProfit Services or any Third Party &/or Open Source Software.</p>

	<h4>16. Termination </h4>
	<p>This Terms of Use is effective unless and until terminated by Us. We may, in Our sole discretion, immediately terminate, limit or suspend Your account, delete Your registration, other information, and/or prohibit You from using or accessing the Platform without any notice, if You have violated any of the provisions in this Terms of Use. On non-payment of Subscription Fees, We reserve Our right to terminate Your access to the Platform.</p>
	<p>If You wish to terminate Your User Account, You may do so by following the instructions on the Platform. On termination, You lose the right to access or use the Platform. However, any such termination of the User Account shall not affect Your obligation to pay for Services already purchased from the Platform or affect any liability of Yours towards MProfit, that may have arisen under the User Account prior to the date of termination.</p>
	<h4>17. Notices</h4>
	<p>Notices from Us: All notices or other communications given to You if:</p>
	<ol type="a">
	<li>Communicated through any print or electronic media as We may select will be deemed to be notified to You on the date of publication or broadcast; or</li>
	<li>Sent by post or left at Your last known address will be deemed to be received by You on the day following such posting or on the day when it was so left.</li>
	</ol>

	<p>Notices from You: You may only give notice to Us via email at support@mprofit.in or in writing sent to our designated address, and We shall be deemed to have received such notice only upon receipt. While We endeavour to respond promptly to notices from You, We cannot guarantee that We will always respond with consistent speed.</p>

	<h4>18. Cancellations</h4>
	<p>Cancellations: All sales made on the Platform are final and You shall not be entitled to cancel Your purchase once You have successfully paid the Subscription Fees. We reserve the sole right to cancel any order as per Our discretion in case (i) We are unable to deliver the order in a satisfactory manner and/ or (ii) the User tries to take advantage of the system which violates the Terms of Use. We will ensure that any communication of cancellation of an order or any applicable refund will be made within a reasonable period of time.</p>
	<h4>19. Indemnity</h4>
	<p>You hereby agree that You shall indemnify, defend, and hold harmless MProfit, including but not limited to its affiliates, agents, directors, and employees from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Us that arise out of, result from, or may be payable by virtue of, any breach, including any unauthorized commercial use of the Services, or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by You pursuant to these Terms of Use. Further, You agree to hold Us harmless against any claims made by any third-party due to, or arising out of, or in connection with, Your use of the Platform, Your violation of the Terms of Use, or Your violation of any rights of another, including any intellectual property rights.</p>
	<p>In no event shall MProfit, its officers, directors, employees, agents or suppliers be liable to You, or any third-party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable or whether or not We have been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortious action, or any other claim arising out of or in connection with Your use of or access to the Platform and its Services.</p>

	<p>The limitations and exclusions in this Clause apply to the maximum extent permitted by applicable law.</p>

	<h4>20. Feedback</h4>
	<p>If You choose to provide input and suggestions regarding problems with or proposed modifications or improvements to the Service (“Feedback”), then You hereby grant us an unrestricted, perpetual, irrevocable, non-exclusive, fully paid, royalty-free right to utilize or use the Feedback in any manner and for any purpose, including to improve the Service and create other products and services.</p>
	<h4>21. Reviews</h4>
	<p>In the event that You provide any reviews for MProfit Software, Services or the Platform, You hereby accept and agree to the display/reference of Your reviews through MProfit advertisements and public relations activities, as well as the use and display of such reviews on various social media handles of MProfit (and/or its affiliates) including but not limited to Facebook, Twitter, LinkedIn and Instagram, as well as the Website, without any compensation to You, in perpetuity.</p>
	<p>MProfit shall have the perpetual right to utilize, reuse, repost or repurpose the reviews in any manner, as it may require from time to time. In the event that You would like to rescind Your consent for such usage of Your review, we request You to kindly contact us at support@mprofit.in.</p>
	<h4>22. Grievances Redressal</h4>
	<p>You agree to use the Platform and any related materials or Services at Your sole risk and that We will have no liability to You for any content that You may deem offensive, indecent, or objectionable. All complaints, queries and suggestions should be sent by email at support@mprofit.in or sent in writing to Us at our designated address. </p>
	<h4>23. Miscellaneous</h4>

	<p><u>23.1 Governing Law, Dispute Resolution and Jurisdiction:</u> These Terms of Use are governed by the laws of India. Any claim related to any dispute arising as a result of the Platform, Services or under these Terms of Use will be made before a court of competent jurisdiction located in Mumbai, Maharashtra. MProfit agrees to resolve such disputes by binding to Arbitration and Conciliation Act, 1996. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the Terms of Use or use of the Platform or Services must be filed within a period of 60 (sixty) days after such claim or cause of action arises or shall be forever barred.</p>
	<p><u>23.2 Cumulative rights and remedies:</u> Unless otherwise provided under these Terms of Use, the provisions of these Terms of Use and Our rights and remedies under these Terms of Use are cumulative and are without prejudice and in addition to any rights or remedies We may have in law or in equity, and no exercise by Us of any one right or remedy under these Terms of Use, or at law or in equity, shall (save to the extent, if any, provided expressly in these Terms of Use or at law or in equity) operate so as to hinder or prevent Our exercise of any other such right or remedy as at law or in equity.</p>
	<p><u>23.3 No Partnership:</u> You agree that no joint venture, partnership, employment, or agency relationship exists between You or other parties and MProfit as a result of these Terms of Use or Your access to or use of the Platform.</p>
	<p><u>23.4 Headings:</u> Headings used in these Terms of Use are for reference purposes only and in no way define or limit the scope of the Clauses.</p>
	<p><u>23.5 Severability:</u> If any provision of these Terms of Use is held to be unenforceable, such provision will be reformed only to the extent necessary to make it enforceable.</p>
	<p><u>23.6 Force Majeure:</u> MProfit shall be under no liability whatsoever in the event of non-availability of any portion of the Platform or subscribed content occasioned by act of God, war, disease, revolution, riot, civil commotion, strike, lockout, pandemic, epidemic, lockdown, flood, fire, failure of any public utility, manmade disaster, infrastructure failure or any other cause whatsoever beyond the control of MProfit.</p>
	<p><u>23.7 Waiver:</u> The failure of MProfit to act with respect to a breach of these Terms of Use by You or others does not constitute a waiver and will not limit Our rights with respect to such breach or any subsequent breaches.</p>
	<p><u>23.8 Survival:</u> The obligations under the provisions of Clauses 4, 6, 7, 8, 9, 10, 11, 14, 19, 20 and 21 will survive any expiration or termination of this Terms of Use.</p>
	<p><u>23.9 Entire Agreement:</u> These Terms of Use and the Privacy Policy on the Platform constitute the entire agreement between You and MProfit and govern Your use of the Platform, superseding any prior agreements between You and MProfit.</p>

	<p><u>23.10 Rights of third parties:</u> A person or entity who is not a party to these Terms of Use shall have no right under the Indian Contracts Act, 1872 as amended from time to time, or any similar legislation in any jurisdiction to enforce any term of these Terms of Use, regardless of whether such person or entity has been identified by name, as a member of a class or as answering a particular description. </p>
	<p><u>23.11 Injunctive relief:</u> We may seek immediate injunctive relief if We make a good faith determination that a breach or non-performance is such that a temporary restraining order or other immediate injunctive relief is the only appropriate or adequate remedy.</p>
	<p><u>23.12 Amendments:</u> We may update the Terms of Use from time to time at Our sole discretion, and the date of the last update shall be reflected at the bottom of this Terms of Use. If You use the Platform or the Services after such date, You are deemed to have accepted such variation. If You do not accept the variation, You must stop accessing or using the Platform and the Services. Our right to vary these Terms of Use in the manner aforesaid will be exercised without the consent of any person or entity.</p>

	<p><u>23.13 Correction of errors:</u> Any typographical, clerical or other error or omission in any acceptance, invoice or other document on Our part shall be subject to correction without any liability on Our part.</p>

	<p><u>23.14 Currency:</u> Money references under the Platform and Services shall be in Indian Rupees, unless otherwise specified.</p>

	<p><u>23.15 Language:</u> In the event that these Terms of Use is executed or translated in any language other than English (“Foreign Language Version”), the English language version of these Terms of Use shall govern and shall take precedence over the Foreign Language Version.</p>

	<p><u>23.16 Binding and conclusive:</u> You acknowledge and agree that any records (including records of any telephone conversations relating to the Services, if any) maintained by Us or Our service providers relating to or in connection with the Platform and Services shall be binding and conclusive on You for all purposes whatsoever and shall be conclusive evidence of any information and/or data transmitted between Us and You. You hereby agree that all such records are admissible in evidence and that You shall not challenge or dispute the admissibility, reliability, accuracy or the authenticity of such records merely on the basis that such records are in electronic form or are the output of a computer system, and You hereby waive any of Your rights, if any, to so object.</p>

	<p><u>23.17 Sub-contracting and delegation:</u> We reserve the right to delegate or sub-contract the performance of any of Our functions in connection with the Platform and/or Services and reserve the right to use any service providers, sub-contractors and/or agents on such terms as We deem appropriate.</p>

	<h3 className={styles.schedule}>SCHEDULE I</h3>

	<h4>1. DEFINITIONS AND INTERPRETATION </h4>

	<h5>1.1 Definitions</h5>

	<p>“MProfit Services” or “Services” means the services that MProfit makes available to the Users, including but not limited to:</p>
	<ul>
	<li>Accessing, installing or using the MProfit Platform</li>
	<li>Internet-based and cloud-based services associated with MProfit Software;</li>
	<li>Software updates, stock prices, mutual funds NAVs, prices of other financial assets, addition of stocks names, mutual fund names & other asset names in the database, name change of any assets, and other information related to financial assets or financial market information;</li>
	<li>MProfit data storage on cloud provided to Users; and</li>
	<li>Upload or import of User’s financial transaction statements into MProfit Software.</li>
	</ul>

	<p>“MProfit Software” shall include any version of MProfit’s portfolio management software application for desktop, any version of MProfit’s portfolio management cloud software application as well as any version of MProfit’s mobile applications, including documentation & user manuals that may be supplied to Users. MProfit Software also includes any free or paid ancillary software products provided to Users by MProfit, updates, upgrades, bug fixes and enhancements to such software products which MProfit, in its sole discretion, may choose to provide to Users.</p>
	<p>“Personal Data” means data, whether true or not, that can be used to identify, contact or locate You. Personal Data can include Your name, e-mail address, phone number, username, password, billing address, GST Number and credit card information.</p>
	<p> “Personal Data” shall be deemed to include information provided to us through our Services, interaction with our customer service, participation in surveys, marketing promotions, user attributes, account preferences and any data that You have provided to Us when signing up for the Platform or Services or when placing a purchase.</p>
	<p>“Prohibited Material” means any information, graphics, photographs, data and/or any other material that:</p>

	<ol type="a">
	<li>contains any computer virus or other invasive or damaging code, program or macro;</li>
	<li>infringes any third-party Intellectual Property or any other proprietary rights;</li>
	<li>is defamatory, libelous or threatening;</li>
	<li>is obscene, pornographic, indecent, counterfeited, fraudulent, stolen, harmful or otherwise illegal under the applicable law; and/or</li>
	<li>is or may be construed as offensive and/or otherwise objectionable, in Our sole opinion.</li>
	</ol>

	<p>“Third Party &/or Open Source Software” means Intellectual Property not owned by MProfit which may be used by You or MProfit for the purpose of integrating MProfit Software and/or Services on Your computer, mobile or tablet devices.</p>

	<p>“Trademarks” means the trademarks, service marks, trade names and logos used and displayed on the Platform.</p>

	<h5>1.2 Interpretation</h5>

	<p>Any reference in these Terms of Use to any provision of a statute shall be construed as a reference to that provision as amended, re-enacted or extended at the relevant time. In the Agreement, whenever the words “include”, “includes” or “including” are used, they will be deemed to be followed by the words “without limitation”. Unless expressly indicated otherwise, all references to a number of days mean calendar days, and the words “month” or “monthly” as well as all references to a number of month’s means calendar months. Clause headings are inserted for convenience only and shall not affect the interpretation of these Terms of Use. In the event of a conflict or inconsistency between any two or more provisions under these Terms of Use, whether such provisions are contained in the same or different documents, such conflict or inconsistency shall be resolved in favor of Us and the provision, which is more favorable to Us, shall prevail.</p>

	<p class={styles.lastUpdate}>Date of last update: 12th December 2023</p>

    </ContentPage>
  </>
)

const PrivacyLink = ({text}) => <Link className={styles.emphasizedLink} to="/privacy">{text}</Link>

const ReferralTermsLink = ({text}) => <Link className={styles.emphasizedLink} to="/referral-terms">{text}</Link>

const RefundPolicyLink = ({text}) => <Link className={styles.emphasizedLink} to="/refund-policy">{text}</Link>

const ThirdPartyLicensesLink = ({text}) => <Link className={styles.emphasizedLink} to="/third-party-licenses">{text}</Link>

export default TermsOfUse
