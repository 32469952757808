import React from "react"
import Layout from "../components/flayout"
import TermsOfUse from "../components/termsOfUse"

const NotFoundPage = () => (
  <Layout darkHeader={true} seoFields={seoFields} showAnnouncement={false}>
      <TermsOfUse />
  </Layout>
)

const seoFields = {
  description: "Read MProfit's terms of use for all MProfit software, services and websites.",
  // lang,
  // meta,
  // keywords,
  title: "Terms of Use" 
}

export default NotFoundPage
